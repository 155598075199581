import '../layout/index.scss'

import React from 'react'
import {Layout} from 'layout/layout'
import {Blocks} from 'blocks/blocks'
import {Hero} from 'blocks/hero/hero'
import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {Block} from 'blocks/block'
import {graphql} from 'gatsby'
import {EditCMS} from 'layout/editcms'
import {BlocksContext, BlocksContextData} from 'blocks/blockscontext'
import {I18n} from 'util/i18'
import * as langs from 'locale'

const NotFoundPage: React.FC<{
    location: {
        pathname: string
    },
    data: any
}> = ({ location: { pathname }, data }) => {

    const nodes = data.pages.nodes
    const node = nodes.find(node => pathname.startsWith(node.path || ''))
    const lang = process.env.GATSBY_DRUPAL_LANG
    const context: BlocksContextData = {
      nodeID: 0,
      blocks: [],
      pageTitle: "404 - Page not found",
      pageType: '404',
      parents: [],
      relatedContent: [],
      relatedJobs: [],
      url: pathname
    }

    if (!node) {
        return (
          <BlocksContext.Provider value={context}>
            <I18n language={lang} data={langs[lang]}>
              <Layout>
                  <Blocks>
                      <Hero
                          title="404 - Page not found"
                      />
                  </Blocks>
              </Layout>
            </I18n>
          </BlocksContext.Provider>
        )
    }

    const paragraphs = node.relationships.field_blocks
    const blocks = loadBlocksFromGraph(paragraphs)

    return (
        <BlocksContext.Provider value={{...context, blocks}}>
          <I18n language={lang} data={langs[lang]}>
            <Layout>
                <EditCMS type="term" id={node.termID} />
                <Blocks>
                    {blocks.map((block, i) => (
                        <Block
                            {...block}
                            blocks={blocks}
                            key={i}
                        />
                    ))}
                </Blocks>
            </Layout >
          </I18n>
        </BlocksContext.Provider>
    )
}

export const query = graphql`
    query {
        pages: allTaxonomyTerm404Pages(sort: {order: ASC, fields: weight}) {
            nodes {
                name
                weight
                termID: drupal_internal__tid
                path: field_404pages_path
                relationships {
                    field_blocks {
                        ...PrototypeBlocks
                    }
                }
            }
        }
    }
`

export default NotFoundPage
